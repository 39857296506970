import { colors, zIndexMap } from '@Backlot-Cars/archie';
import { media, size } from 'public/styles';
import styled from 'styled-components';

export const StyledHeader = styled.header`
	align-items: center;
	background-color: ${(props) => colors[props.background]};
	display: flex;
	height: ${size.header.mxl};
	justify-content: space-between;
	left: 0;
	padding: 0 ${size.margin.normal.mxl};
	position: absolute;
	top: 0;
	transition: background 0.15s ease-in-out;
	z-index: ${zIndexMap.navigationBar};
	width: 100%;

	@media ${media.largest} {
		height: ${size.header.mxl};
		padding: 0 ${size.margin.normal.mxl};
	}
	@media ${media.larger} {
		height: ${size.header.mxl};
		padding: 0 ${size.margin.normal.ml};
	}
	@media ${media.smaller} {
		height: ${size.header.mxl};
		padding: 0 ${size.margin.normal.ms};
	}
	@media ${media.smallest} {
		height: ${size.header.mxl};
		padding: 0 ${size.margin.normal.mxs};
	}
`;
