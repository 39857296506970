import { OpenLaneLogo } from '@Backlot-Cars/archie';
import { useRouter } from 'next/router';
import { StyledHeader } from './styles.js';

const BlcTopbar = ({ background = 'transparent' }) => {
	const router = useRouter();
	const { hideLogo } = router.query;

	return (
		<StyledHeader background={background}>
			{!hideLogo && <OpenLaneLogo width={84} height={64} variant="vertical" />}
		</StyledHeader>
	);
};

export default BlcTopbar;
